
import React, {useEffect, useState} from 'react'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { useNavigate } from "react-router-dom";


const salespeople = [
  { id:2, salesperson:'Adele Weiss'},
  { id:3, salesperson:'Alan Goldstein'},
  { id:5, salesperson:'Alessandra Wendroff'},
  { id:6, salesperson:'Alexander Wolfe'},
  { id:7, salesperson:'Allasandrina Soffa'},
  { id:8, salesperson:'Amanda Gardley'},
  { id:10, salesperson:'Arielle Moore'},
  { id:12, salesperson:'Bianca Marcazzo'},
  { id:15, salesperson:'Brittany Tessler'},
  { id:16, salesperson:'Cailin Doherty'},
  { id:17, salesperson:'Caroline Coyer'},
  { id:19, salesperson:'Christina Muranelli'},
  { id:20, salesperson:'Christopher Diaz'},
  { id:22, salesperson:'Courtney Battle'},
  { id:24, salesperson:'Danielle Mudge'},
  { id:25, salesperson:'Dave Frieder'},
  { id:27, salesperson:'Debra Ashley'},
  { id:28, salesperson:'Dennis Burger'},
  { id:29, salesperson:'Derek Raneri'},
  { id:30, salesperson:'Elizabeth Sloan'},
  { id:33, salesperson:'Erin Whelan'},
  { id:34, salesperson:'Evan Noonan'},
  { id:35, salesperson:'Gaby Markey'},
  //{ id:37, salesperson:'Giancarlo Silva'},
  { id:40, salesperson:'Henry Palermo'},
  { id:42, salesperson:'Jacqueline Helweil'},
  { id:45, salesperson:'Jason Laderman'},
  { id:46, salesperson:'Jason Mezic'},
  { id:48, salesperson:'Jennifer Paulenoff'},
  { id:50, salesperson:'Jessica Lieberman'},
  { id:51, salesperson:'Jessie Koverman'},
  //{ id:52, salesperson:'Jim Cassaro'},
  { id:54, salesperson:'John Winchell'},
  { id:55, salesperson:'Jonathan Wesley'},
  { id:56, salesperson:'Jordan Gipson'},
  { id:58, salesperson:'Justen Creppy'},
  { id:61, salesperson:'Kara Tricarico'},
  { id:62, salesperson:'Karla Nielsen'},
  { id:64, salesperson:'Kerry Zuaro'},
  { id:65, salesperson:'Kevin Feinstein'},
  { id:67, salesperson:'Kristen Albanese'},
  { id:68, salesperson:'Kristin Rachlin'},
  { id:69, salesperson:'Kurt Hutchinson'},
  { id:71, salesperson:'Lauren Morris'},
  { id:75, salesperson:'Lyset Monson'},
  { id:76, salesperson:'Maggie Lessing'},
  { id:79, salesperson:'Mark Rodas'},
  { id:82, salesperson:'Megan O\'Brien'},
  { id:84, salesperson:'Michael Elias'},
  { id:85, salesperson:'Michael Mueller'},
  { id:87, salesperson:'Morgan Driscoll'},
  { id:90, salesperson:'Paul Mugler'},
  { id:92, salesperson:'Raymond Semente'},
  { id:93, salesperson:'Regina Conlin'},
  { id:94, salesperson:'Ryan Murray'},
  { id:95, salesperson:'Sanya Lewin'},
  { id:96, salesperson:'Sarik Kumar'},
  { id:97, salesperson:'Sawyer Harris'},
  { id:98, salesperson:'Shakira Hill'},
  { id:99, salesperson:'Shera Mayer'},
  { id:101, salesperson:'Stephanie Kahn Woods'},
  { id:102, salesperson:'Stephanie Leslie'},
  { id:104, salesperson:'Tamara Jones'},
  { id:107, salesperson:'Wanda Sutherland'},
  { id:141, salesperson:'Joy Sipple'},
  { id:38, salesperson:'CBS Local - Detroit'},
  { id:149, salesperson:'CBS Local - New York'},
  { id:161, salesperson:'CBS Local - Sacramento'},
  { id:173, salesperson:'CBS Local - Minneapolis'},
  { id:190, salesperson:'Darryl Hendricks'},
  { id:194, salesperson:'CBS Local - Pittsburgh'},
  { id:202, salesperson:'CBS Local - Atlanta'},
  { id:212, salesperson:'CBS Local - Denver'},
  { id:219, salesperson:'CBS Local - Chicago'},
  { id:237, salesperson:'CBS Local - Baltimore'},
  { id:238, salesperson:'CBS Local - Tampa'},
  { id:239, salesperson:'CBS Local - Boston'},
  { id:241, salesperson:'CBS Local - Seattle'},
  { id:244, salesperson:'CBS Local - Philadelphia'},
  { id:250, salesperson:'CBS Local - Dallas'},
  { id:259, salesperson:'CBS Local - Miami'},
  { id:280, salesperson:'CBS Local - Los Angeles'},
  { id:287, salesperson:'CBS Local - San Francisco'},
  { id:290, salesperson:'Meaghan Black'},
  { id:293, salesperson:'Siobhan Elvin'},
  { id:297, salesperson:'Rachel Oliveros'},
  { id:298, salesperson:'Kendall Doherty'},
  { id:300, salesperson:'Samantha Faust'},
  { id:301, salesperson:'Colleen Gerndt'},
  { id:303, salesperson:'Patrick Salter'},
  { id:304, salesperson:'Jessica Assaad'},
  { id:305, salesperson:'Stephen Russell'},
  { id:306, salesperson:'Jessica Heard'},
  { id:307, salesperson:'Nicholas Williams'},
  { id:308, salesperson:'Odean Mangum'},
  { id:309, salesperson:'Alden Geller'},
  { id:310, salesperson:'Chris Reil'},
  { id:311, salesperson:'Cristina Falbo'},
  { id:317, salesperson:'Craig Harrison'},
  { id:319, salesperson:'Sean Sultanik'},
  { id:320, salesperson:'Amil Shah'},
{ id:321, salesperson:'Christa Thompson'},
{ id:322, salesperson:'Enitan Fe-Cados'},
{ id:323, salesperson:'Michelle Edobor'},
{ id:324, salesperson:'Mike Thomas'},
{ id:325, salesperson:'Robin Simpson'},
{ id:326, salesperson:'Victoria Ivanova'},
{ id:327, salesperson:'Zach Belmont'},
{ id:328, salesperson:'Jennifer L Bialowarczuk'},

]


  function SalespersonSelector(salespersonId) {
    const [salesperson, setSalesperson] = useState(salespersonId);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);


//console.log(token)


    
    useEffect(() => {
      salespeople.sort((a,b) => (a.salesperson > b.salesperson) ? 1 : ((b.salesperson > a.salesperson) ? -1 : 0))

      //let assignmentArray = []
      let assignmentArray = JSON.parse(localStorage.getItem('dashboard'))//
      //console.log(assignmentArray)
      //console.log(typeof(assignmentArray))

      if (
        typeof assignmentArray === 'object' &&
        Array.isArray(assignmentArray) &&
        assignmentArray !== null
    ) {

       // console.log("selected salespeople found")
        assignmentArray.forEach(assigned => salespeople.unshift(salespeople.splice(salespeople.findIndex(item => item.id === assigned), 1)[0]));

      } else {
        salespeople.sort((a,b) => (a.salesperson > b.salesperson) ? 1 : ((b.salesperson > a.salesperson) ? -1 : 0))

        console.log("no salespeople selected")

      }

      setLoading(false);



    //  setLoading(true);
     // console.log("selector")
     // console.log(token)
      //let token = localStorage.getItem("token")
     // console.log(role)
     /*
      var options = {
        method: 'GET',
        headers: {"Content-Type": "application/json"}
  
      }
      options.headers["Authorization"] = "Bearer " + token;

        fetch("/user/"+user.uid, options).then(
          response => response.json()
        ).then(
          data => {
            console.log(data)
            console.log(data.assignments)
            let assignmentArray = data.assignments
            assignmentArray.forEach(assigned => salespeople.unshift(salespeople.splice(salespeople.findIndex(item => item.id === assigned), 1)[0]));
            setLoading(false)
          }
        )
        */
      }, [])




    


    const handleChange = (event) => {
        setSalesperson(event.target.value);
      //  console.log(event.target.value)
        let path = "/dashboard/"+event.target.value; 
        window.location.href=path
      //  navigate(path);
      };

if(loading){
    return (
      <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
      <InputLabel id="demo-simple-select-filled-label">Salesperson</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="demo-simple-select-filled"
        value={salespersonId.salespersonId}
        onChange={handleChange}
      >

{salespeople.map((data, i) => (
                    <MenuItem key={i} value={data.id}>{data.salesperson}</MenuItem>

        ))}

      </Select>
      </FormControl>
    )
}else {
    return (
        <FormControl variant="filled" sx={{marginBottom:'10px', marginTop:'10px', minWidth: 120 }}>
        <InputLabel id="demo-simple-select-filled-label">Salesperson</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={salespersonId.salespersonId}
          onChange={handleChange}
        >

{salespeople.map((data, i) => (
                      <MenuItem key={i} value={data.id}>{data.salesperson}</MenuItem>

          ))}

        </Select>
        </FormControl>
    )
}
  }

  export default SalespersonSelector